<template>
<div class="modules">
    <div class="user-pane" v-if="false">
        <div class="userinfo">
            <div class="avatar">
                <el-avatar :size="50" ></el-avatar>
            </div>
            <div class="info">
                <div class="username">Anonymous User</div>
                <div class="status"></div>
            </div>
        </div>
        <div class="metas">
            <div class="meta">
                <div class="meta-title">
                    User ID <i class="icon el-icon-document-copy"></i>
                </div>
                <div class="meta-val">
                    20799128
                </div>
            </div>
            <div class="meta">
                <div class="meta-title">
                    VIP Level <i class="icon el-icon-arrow-right"></i>
                </div>
                <div class="meta-val">
                    Regular User
                </div>
            </div>
            <div class="meta">
                <div class="meta-title">
                    User Type
                </div>
                <div class="meta-val">
                    Personal
                </div>
            </div>
        </div>
    </div>
    <div class="balance">
        <div class="hd"> 
            <div class="title">
                 <div class="textNumber">
                    <div class="text">{{ $t("lang.estimated") }}</div>
                    <div class="icon-eye" :class="{'close':!isView}" @click="toggleView"> </div>
                    <div class="sel">
                        <el-select v-model="value"  @change="selectChanged">
                           <el-option class="option"  v-for="(item,index) in networkVos" :key="index"  :label="item.fullName" :value="item.id"> 
                              <span class="custom-template">{{ item.fullName }}</span>
                              <!-- <i class="custom-icon el-icon-info"></i> -->
                          </el-option>
                        </el-select> 
                    </div>
                 </div>
                <!-- <div class="text">{{ $t("lang.estimated") }}</div> -->
                <div class="val">
                    <div>
                        <!-- <el-select v-model="balance" class="select" size="small">
                            <el-option value="0.00017782" label="0.00017782 BTC"></el-option>
                        </el-select> -->
                        <div class="exchange">
                            <span class="exchange-show" v-if="isView">
                                <span class="num">{{btFull.surplusRevoCoin}}</span> 
                                <span class="unit">{{btFull.btUnit}}</span>

                                <span class="num">( {{btFull.lockRevoCoin}} {{btFull.btUnit}} )</span> 
                            </span>
                            <span class="exchange-show" v-else>
                                <span class="num">***</span> 
                            </span>
                            <!-- <el-dropdown>
                                <span class="el-dropdown-link"><i class="el-icon-arrow-down"></i></span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item>0.00017782 BTC</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown> -->
                        </div>
                    </div>
                    <!-- <div class="symbol">≈</div>
                    <div class="dollar">$6.07</div> -->
                </div>
            </div>
            <div class="extra">
                <el-button class="bt" :class="{'disabled':!enabledRecharge}" size="mini" @click="gotoRouter(1)" >{{ $t("lang.deposit") }}</el-button>
                <el-button class="bt" :class="{'disabled':!enabledWithdrawal}" size="mini" @click="gotoRouter(2)">{{ $t("lang.withdraw") }}</el-button>
                <!-- <el-button size="mini">Buy Crypto</el-button> -->
            </div>
        </div>
        <div class="bd"> 
            <div class="tools">
                <div class="days">
                    <div class="item" :class="{'active':activeSeven}" @click="tabSize(7)">7 Days</div>
                    <div class="item" :class="{'active':activeThirty}" @click="tabSize(30)">30 Days</div>
                </div>
            </div>
            <div class="chart-container">
                <line-chart :dataOption="chartData"></line-chart>
            </div>
        </div>
    </div>
    <div class="markets" >
        <div class="hd">
            <h3 class="title">{{ $t("lang.funding") }}</h3>
        </div>
        <div class="bd">
            <el-table :data="overviewStatsList">
                <el-table-column :label="$t('lang.deposit')" prop="rechargeSum" >
                    <template slot-scope="scope">
                        <div class="td-coin">
                            <div class="item-info" @click="$router.push({name:'transaction', params:{flowType:'recharge'}})">
                                <div class="item-title">{{ scope.row.rechargeSum }}</div>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('lang.opting')" prop="gameGetSum" >
                    <template slot-scope="scope">
                        <div class="td-coin">
                            <div class="item-info" @click="$router.push({name:'transaction', params:{flowType:'G'}})">
                                <div class="item-title">{{ scope.row.gameGetSum }}</div>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('lang.TransactionID')" prop="expensesSum">
                    <template slot-scope="scope">
                        <div class="td-coin">
                            <div class="item-info" @click="$router.push({name:'transaction', params:{flowType:'TX'}})">
                                <div class="item-title">{{ scope.row.expensesSum }}</div>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('lang.Payouts')" prop="withdrawalSum">
                    <template slot-scope="scope">
                        <div class="td-coin">
                            <div class="item-info" @click="$router.push({name:'transaction', params:{flowType:'withdrawal'}})">
                                <div class="item-title">{{ scope.row.withdrawalSum }}</div>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('lang.gas')" prop="gasSum">
                    <template slot-scope="scope">
                        <div class="td-coin">
                            <div class="item-info" @click="$router.push({name:'transaction', params:{flowType:'withdrawal_fees'}})">
                                <div class="item-title">{{ scope.row.gasSum }}</div>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('lang.LockUp')" prop="lockRevoCoinSum">
                    <template slot-scope="scope">
                        <div class="td-coin">
                            <div class="item-info" >
                                <div class="item-title">{{ scope.row.lockRevoCoinSum }}</div>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('lang.TotalRevenue')" prop="allotSum">
                    <template slot-scope="scope">
                        <div class="td-coin">
                            <div class="item-info" @click="$router.push({name:'teamwork'})" >
                                <div class="item-title">{{ scope.row.allotSum }}</div>
                            </div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</div>
</template>
<script>
import "@/assets/fonts/fonts.css";
import lineChart from "./modules/lineChart.vue";
import {BASE_GLOB_API} from "@/config.js";
import {axiosGet,axiosPost} from '@/api/api';
import EventBus from '@/utils/EventBus';

export default {
    name:'dashboard',
    components:{
        lineChart,
        
    },
    data(){
        return {
            url:{
                userinfo:BASE_GLOB_API+'/kanban/wallet/info',
                chart:BASE_GLOB_API+'/kanban/serial/chart',
                platform:BASE_GLOB_API+'/kanban/coin/platform',
                overviewStats:BASE_GLOB_API+'/kanban/overviewStats',
            },
            Language: "en",
            balance:'0.00017782',
            dataSource:[
                {id:1, icon:'../../../assets/img/coins/BTC.svg', name:'BTC', desc:'BTC', amount:6.06926837, price:'$1.00',}
            ],
            chartData:{},
            isView:true,
            activeSeven:true,
            activeThirty:false,
            lang: "",
            networkVos: [],
            btFull:{},
            value: '',
            enabledRecharge:false,
            enabledWithdrawal:false,
            overviewStatsList:[]
          
        }
    },
    mounted(){
       
        
    },
    created(){
      this.lang = sessionStorage.getItem("Language")
      this.platform()
    },
    methods:{
        gotoRouter(value){
            if(value ==1){
                if(this.enabledRecharge){
                    this.$router.push({name:"payment"})
                    // EventBus.$emit('router',  value);
                }
            }
            if(value ==2){
                if(this.enabledWithdrawal){
                    this.$router.push({name:"withdrawal"})
                    // EventBus.$emit('router',  value);
                }
                
            }
           
        },
        selectChanged(index){
          console.log("this.value",this.value)
          this.UserInfo(this.value)
          let areList=[]
          this.networkVos.forEach(item=>{
          if(item.id == this.value){
            let source ={
                enabled:item.enabled,
                fullName:item.fullName,
                icon:item.icon,
                id:item.id,
                shortName:item.shortName,
                unit:item.unit,
                networkVos:item.networkVos,
                enabledRecharge:item.enabledRecharge,
                enabledWithdrawal:item.enabledWithdrawal
            }
                areList.push(source)
           }
         });
         let selectedNetPc = {
            fullName:areList[0].fullName,
            btPlatformId:areList[0].id,
            networkName:areList[0].networkVos[0].networkName,
            btPlatformNetworkId:areList[0].networkVos[0].id,
            enabledRecharge:areList[0].enabledRecharge,
            enabledWithdrawal:areList[0].enabledWithdrawal,
         } 
         this.enabledRecharge=areList[0].enabledRecharge
         this.enabledWithdrawal=areList[0].enabledWithdrawal
         window.sessionStorage.setItem("selectedNetPc", JSON.stringify(selectedNetPc));
         EventBus.$emit('enabledRecharge',  this.enabledRecharge);
         EventBus.$emit('enabledWithdrawal', this.enabledWithdrawal);
        },
        platform(){
            axiosGet(this.url.platform).then(res=>{
               if(res.code ==200){
                let date =res.result
                 if(date.length > 0){
                    date.forEach(item=>{
                       if(item.enabled){
                        let source ={
                            enabled:item.enabled,
                            fullName:item.fullName,
                            icon:item.icon,
                            id:item.id,
                            shortName:item.shortName,
                            unit:item.unit,
                            networkVos:item.networkVos,
                            enabledRecharge:item.enabledRecharge,
                            enabledWithdrawal:item.enabledWithdrawal
                        }
                        this.networkVos.push(source)
                      }
                    });
                   
                    this.enabledRecharge=this.networkVos[0].enabledRecharge
                    this.enabledWithdrawal=this.networkVos[0].enabledWithdrawal
                    // this.enabledRecharge=false
                    // this.enabledWithdrawal=false
                    let selectedNet = JSON.parse( sessionStorage.getItem("selectedNetPc"));
                    if(selectedNet){
                        console.log("selectedNetPc123",selectedNet);
                        this.value =selectedNet.btPlatformId
                        this.networkVos.forEach(item=>{
                          if(item.id == selectedNet.btPlatformId){
                            let selectedNetPc = {
                                fullName:item.fullName,
                                btPlatformId:item.id,
                                networkName:item.networkVos[0].networkName,
                                btPlatformNetworkId:item.networkVos[0].id,
                                enabledRecharge:item.enabledRecharge,
                                enabledWithdrawal:item.enabledWithdrawal,
                            } 
                            console.log("selected",selectedNetPc)
                            window.sessionStorage.setItem("selectedNetPc", JSON.stringify(selectedNetPc));
                          }
                
                        });
                        let selectedNetPc = JSON.parse( sessionStorage.getItem("selectedNetPc"));
                        this.enabledRecharge=selectedNetPc.enabledRecharge
                        this.enabledWithdrawal=selectedNetPc.enabledWithdrawal
                        this.UserInfo(this.value)
                    }else{
                        this.value =this.networkVos[0].id
                        this.UserInfo(this.value)
                        let selectedNetPc = {
                            fullName:this.networkVos[0].fullName,
                            btPlatformId:this.networkVos[0].id,
                            networkName:this.networkVos[0].networkVos[0].networkName,
                            btPlatformNetworkId:this.networkVos[0].networkVos[0].id,
                            enabledRecharge:this.networkVos[0].enabledRecharge,
                            enabledWithdrawal:this.networkVos[0].enabledWithdrawal,
                        } 
                        window.sessionStorage.setItem("selectedNetPc", JSON.stringify(selectedNetPc));
                    }
                    
        
                 }
                 EventBus.$emit('enabledRecharge',  this.enabledRecharge);
                 EventBus.$emit('enabledWithdrawal', this.enabledWithdrawal);
              
               }else{
                    this.$message.error(res.message);
                }
            })
           
        }, 
        toggleView(){
            this.isView = !this.isView;
        },
        tabSize(value){
            if(value ==7){
                this.activeSeven=true;
                this.activeThirty=false;
            }else{
                this.activeSeven=false;
                this.activeThirty=true;
            }
            axiosPost(this.url.chart,{query: {btPlatformId:this.value},size:value}).then(res=>{
               if(res.code ==200){
                let data = res.result, xData = [], yData1 = [], yData2 = [], yData3 = [], yData4 = [];
                data.forEach(item=>{
                    xData.push(item.date);
                    yData1.push(item.expensesRevoCoin);
                    yData2.push(item.incomeRevoCoin);
                    yData3.push(item.rechargeRevoCoin);
                    yData4.push(item.withdrawalRevoCoin);
                });
                this.chartData = {
                    title: {
                        //text: "测试表格"
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    grid:{
                        right:'2%'
                    },
                    xAxis: {
                        type: 'category',
                        data: xData
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [{
                        data: yData1,
                        type: 'line',
                        itemStyle: {
                            color: "rgb(165,0,254)",
                        },
                    },{
                        data: yData2,
                        type: 'line',
                        itemStyle: {
                            color: "rgb(229,179,60)",
                        },
                    },{
                        data: yData3,
                        type: 'line',
                        itemStyle: {
                            color: "rgb(47,149,220)",
                        },
                    },{
                        data: yData4,
                        type: 'line',
                        itemStyle: {
                            color: "rgb(234,98,98)",
                        },
                    }]
                }
               }else{
                    this.$message.error(res.message);
                }
            })
           
        }, 
        UserInfo(value){
            axiosGet(this.url.userinfo,{btPlatformId:value}).then(res=>{
               if(res.code ==200){
                  this.btFull =res.result
                  window.sessionStorage.setItem("btFull", JSON.stringify(this.btFull));
                  this.fetchChart( this.btFull)
                  this.overviewStats(this.btFull)
               }else{
                    this.$message.error(res.message);
                }
            })
           
        }, 
        overviewStats(value){
            axiosPost(this.url.overviewStats,{btPlatformId:value.btPlatformId,walletId:value.id}).then(res=>{
               if(res.code ==200){
                  this.overviewStatsList = [res.result]
               
               }else{
                    this.$message.error(res.message);
                }
            })
           
        }, 
        fetchChart(value){
                this.activeSeven=true;
                this.activeThirty=false;
            axiosPost(this.url.chart,{query: {btPlatformId:value.btPlatformId}, size:7}).then(res=>{
                console.log("res:",res)
                let data = res.result, xData = [], yData1 = [], yData2 = [], yData3 = [], yData4 = [];
                data.forEach(item=>{
                    xData.push(item.date);
                    yData1.push(item.expensesRevoCoin);
                    yData2.push(item.incomeRevoCoin);
                    yData3.push(item.rechargeRevoCoin);
                    yData4.push(item.withdrawalRevoCoin);
                });
                this.chartData = {
                    title: {
                        //text: "测试表格"
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    grid:{
                        right:'2%'
                    },
                    xAxis: {
                        type: 'category',
                        data: xData
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [{
                        data: yData1,
                        type: 'line',
                        itemStyle: {
                            color: "rgb(165,0,254)",
                        },
                    },{
                        data: yData2,
                        type: 'line',
                        itemStyle: {
                            color: "rgb(229,179,60)",
                        },
                    },{
                        data: yData3,
                        type: 'line',
                        itemStyle: {
                            color: "rgb(47,149,220)",
                        },
                    },{
                        data: yData4,
                        type: 'line',
                        itemStyle: {
                            color: "rgb(234,98,98)",
                        },
                    }]
                }
            })
        }, 
    }
}
</script>
<style lang="less" scoped>
.user-pane{
    background:#f4f5f6; border-radius:4px; padding:30px; display:flex; justify-content: space-between; align-items: center; margin-bottom:30px;
    .userinfo {
        display:flex; justify-content: flex-start; align-items: center;
        .info {
            flex:1; padding:0 0 0 15px;
            .username {
                font-weight:700; font-size:16px;
            }
        }
    }
    .metas {
        display: flex; align-items: flex-start; align-items: center;
        .meta {
            padding:0 20px;
            .meta-title {
                font-size:12px; color:#999;
                .icon {
                    cursor: pointer;
                    &:hover {
                        color:#4458fe;
                    }
                }
            }
            .meta-val {
                font-size:14px; color:#333; font-weight:700;
            }
        }
    }
}
.balance {
    margin-bottom:30px;
    .hd {
        display:flex; justify-content: space-between; align-items: center;
        .title {
            .textNumber{
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }
            .text {
                font-size:18px; font-weight:700; padding:0 0 10px;
            }
            .icon-eye {
                background:url(~@/assets/img/icon_eye.svg) no-repeat center center/contain; width:14px; height:14px; padding: 0 0 0 20px;margin: 0px 0 10px 20px;
                &.close {
                    background-image:url(~@/assets/img/icon_closed_eye.svg);
                }
            }
            .sel{
                margin: 0px 0 0 30px; color: #999;
               .option{
                .custom-template {
                    display: inline-block;
                    padding-right: 5px;
                    &.action{
                       color: red;
                    }
                  }
               }
            }
            .val {
                display:flex; justify-content: flex-start; align-items: center;
                ::v-deep .select {
                    .el-input {
                        position:relative; width:auto;
                        .el-input__inner {border:0 none; font-size:24px; font-weight:700; font-family: 'bebas_neueregular'; padding-left:0; display:block; width:100%; min-width:0;}
                        .el-input__suffix {
                            background:#f4f5f6; border-radius:4px;
                        }
                        &:after {
                            content:""; display:block; border-bottom:#ccc 1px dashed; margin-right:32px;
                        }
                    }
                }
                .exchange {
                    display:flex; justify-content: flex-start; align-items: center; margin-right:6px;
                    .exchange-show {
                        display:flex; justify-content: flex-start; align-items: center;
                        .num {font-family: 'bebas_neueregular'; font-size:24px; display:block;}
                        .unit {font-size:20px; font-weight:700; display:block; margin:0 8px 0 4px;}
                    }
                    .el-dropdown-link {
                        width:26px; height:26px; display:block; border-radius:4px; background:#f4f5f6; display:flex; justify-content: center; align-items: center; margin-left:4px; cursor: pointer;
                    }
                }
                .symbol, .dollar {
                    font-size:20px; color:#666; margin:0 4px; font-weight:700;
                }
            }
        }
        .extra {
           .bt{
              &.disabled {opacity:.6; filter: grayscale(100%); color: #ccc;}
           }
        }
    }
    .bd {
        padding:15px 0;
        .days {
            display:flex; justify-content: flex-start; align-items: center;
            .item {
                font-size:14px; padding:6px 16px; border-radius:4px; margin:0 6px 0 0; cursor: pointer;
                &.active {
                    background:#f4f5f6; font-weight:700;
                }
            }
        }
    }
}
.markets {
    .hd {
        .title {
            font-size:18px; font-weight:700;
        }
    }
}
.td-coin {
    display:flex; justify-content: space-between; align-items: center;
    .item-icon {margin-right:4px;}
    .item-info {
        flex:1; line-height:1.4;
        .item-title {font-weight:700;}
        .item-desc {
            color:#999; font-size:12px;
        }
    }
}
</style>